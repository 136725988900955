import React, { useRef } from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import googlePlay from "../../assets/images/google-play.png";
import appStore from "../../assets/images/app-store.png";
import { useClampedIsInViewport } from "../../plugins/use-clamped-is-in-viewport";
import AppSettings from "../../constants/AppSettings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  logoContainer: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    maxWidth: theme.spacing(20),
    maxHeight: theme.spacing(20),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.appIcon,
    borderRadius: "50%",
    display: "flex",
    margin: "auto",
  },
  logo: {
    width: "55%",
    height: "55%",
    margin: "auto",
  },
  title: {
    fontWeight: theme.title.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.title.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.title.downSm.fontSize,
    },
  },
  description: {
    maxWidth: theme.spacing(62),
    marginLeft: "auto",
    marginRight: "auto",
  },
  downloadButton: {
    maxWidth: theme.spacing(25),
    maxHeight: theme.spacing(8),
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius * 1.5,
    boxShadow: theme.shadow.appDownloadButton,
  },
}));

export default function DownloadApps(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const ref = useRef(null);
  const [isInViewport, targetRef] = useClampedIsInViewport({
    target: ref,
  });

  return (
    <div className={classes.root} ref={targetRef}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          {/* Title and Description row */}
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={`${classes.title} ${
                isInViewport ? "animate__animated animate__backInLeft" : "hide"
              }`}
            >
              {t("DownloadApps_sharelink")}
            </Typography>
            <p
              className={`${classes.description} ${
                isInViewport ? "animate__animated animate__backInRight" : "hide"
              }`}
            >
              {t("DownloadDescription")}
            </p>
          </Grid>

          {/* Download Buttons row */}
          <Grid item xs={12}>
            <Grid container spacing={3} justify="center" wrap="wrap">
              <Grid item>
                <ButtonBase href={AppSettings.Links.GooglePlayStoreLink}>
                  <img
                    src={googlePlay}
                    alt="Google Play"
                    className={classes.downloadButton}
                  />
                </ButtonBase>
              </Grid>
              <Grid item>
                <ButtonBase href={AppSettings.Links.AppStoreLink}>
                  <img
                    src={appStore}
                    alt="App Store"
                    className={classes.downloadButton}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={`${classes.title} ${
                isInViewport ? "animate__animated animate__backInLeft" : "hide"
              }`}
            >
              {t("info1")}
              <br />
              <br />
              {t("info2")}
              <br />
              <br />
              {t("info3")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
