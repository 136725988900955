export default {
  translation: {
    OpenApp: "باز کردن اپ",
    FAQ: "سوالات متداول",
    AboutUs: "درباره ما",
    Tutorials: "آموزش",
    Blog: "بلاگ",
    MyPlansTitle: "برنامه های من",
    MyPlansDescription:
      "لیست برنامه هایی که امکان طراحی آن را دارم در این قسمت به شما نشان داده می شود. توجه داشته باشید که هیچ کدام از این برنامه ها از پیش طراحی نشده و بر اساس شرایط فیزیکی شما طراحی می گردد. ضمنا می توانید پس از نصب اپلیکیشن رمبادی، از طریق دکمه چت در پروفایلم با من در ارتباط باشید.",
    ExpertiseTitle: "رشته تخصصی",
    AboutMeTitle: "درباره من",
    DownloadApps: "دانلود اپلیکیشن",
    DownloadApps_sharelink: "۱. دانلود اپلیکیشن",
    DownloadDescription: "",
    TutorialVideo: "آموزش",
    TutorialVideoDescription:
      "ویدیوهای آموزشی جهت استفاده بهتر و آگاهی از طریقه طراحی برنامه های تمرینی را در اینجا مشاهده نمایید",
    CompanyDescription:
      "رمبادی پلتفرم ارتباطی بین مربی و ورزشکار جهت ارائه برنامه تمرینی و غذایی اختصاصیست",
    AllRightsReserved: "2019 تمام حقوق محفوظ است. رمبادی.",
    CompanyName: "Farosa Technologies Inc",
    Level: "مرحله",
    HowToApplyProgram: "چگونه درخواست برنامه دهم؟",
    ApplyProgramLevel1: "دانلود اپلیکشن رمبادی",
    ApplyProgramLevel2: "جستجوی آیدی من {0} در اپلیکیشن",
    ApplyProgramLevel3: "درخواست یکی از برنامه ها بر اساس نیاز و هدف تان",
    ApplyProgramLevel4: "طراحی و ارسال برنامه در مدت زمان تعیین شده",
    Close: "بستن",
    ReadMore: "بیشتر",
    Less: "کمتر",
    experimental: "تجربی",
    expert: "تخصصی",
    amateur: "آماتور",
    totalSentPlans: "تعداد برنامه های فروخته شده",
    download: "دانلود اپلیکیشن رمبادی",
    requestPackage: "درخواست برنامه",
    info1: "۲. در اپلیکیشن ثبت نام کنید",
    info2: "۳. به همین صفحه برگردید",
    info3: "۴. روی دکمه زیرکلیک کنید",
  },
};
