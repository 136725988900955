import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { formatString } from "@rambody/commons/lib/extentions/string-helper";
import { isAndroid } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppBar from "../components/Common/AppBar";
import AppFooter from "../components/Common/AppFooter";
import BootstrapButton from "../components/Common/BootstrapButton";
import AppSettings from "../constants/AppSettings";
import DownloadAppsShareLink from "../components/TrainerProfile/DownloadAppsShareLink";

interface MatchParams {
  packageId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.header.background.main,
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 1,
      position: "relative",
      "&:before": {
        zIndex: 0,
        pointerEvents: "none",
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "block",
        backgroundColor: theme.palette.common.black,
        opacity: 0.35,
      },
    },
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  openApp: {
    marginTop: theme.spacing(2),
    width: "70%",
    borderRadius: 12,
    height: theme.spacing(8),
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(22),
    },
  },
  centeredText: {
    textAlign: "center",
    color: theme.footer.text.primary,
    opacity: 0.99,
    fontSize: theme.typography.pxToRem(18),
  },
}));

const SharePackageContainer: React.FC = () => {
  const classes = useStyles();
  const { packageId } = useParams<MatchParams>();
  const { t } = useTranslation();

  const handleOpenAppClick = (): void => {
    // Open the app if installed; otherwise, redirect to the store.
    window.location.href = formatString(
      AppSettings.Links.OpenPackageAppLink,
      packageId || ""
    );
    setTimeout((): void => {
      if (isAndroid) {
        window.location.href = AppSettings.Links.GooglePlayStoreLink;
      } else {
        window.location.href = AppSettings.Links.AppStoreLink;
      }
    }, 3000);
  };

  return (
    <div className={classes.root}>
      <AppBar />
      <DownloadAppsShareLink />
      <div className={classes.content}>
        <BootstrapButton
          className={classes.openApp}
          onClick={handleOpenAppClick}
        >
          {t("requestPackage")}
        </BootstrapButton>
        <br />
        <br />
        <br />
      </div>
      <AppFooter />
    </div>
  );
};

export default SharePackageContainer;
