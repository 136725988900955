export default {
  translation: {
    OpenApp: "افتح التطبيق",
    FAQ: "التعليمات",
    AboutUs: "عرضي العام",
    Tutorials: "دروس",
    Blog: "مدونة",
    MyPlansTitle: "حزمتي",
    MyPlansDescription:
      "يمكنك الاطلاع على قائمة الحزم الخاصة بي التي يمكنني تصميمها في هذا القسم. لاحظ أن هذه الحزم لم يتم إعدادها مسبقًا وهي مصممة بناءً على ظروفك المادية وهدفك. يمكنك أيضًا الاتصال بي عبر زر الدردشة في ملفي الشخصي بعد تثبيت تطبيق رمبادي.",
    ExpertiseTitle: "خبرتي",
    AboutMeTitle: "عرضي العام",
    DownloadApps: "تنزيل التطبيقات",
    DownloadApps_sharelink: "۱. قم بتنزيل التطبيق",
    DownloadDescription: "",
    TutorialVideo: "الدورة التعليمية",
    TutorialVideoDescription:
      "شاهد مقاطع الفيديو التعليمية هنا لاستخدام أفضل ومعرفة كيفية تصميم خطط التدريب.",
    CompanyDescription:
      "رمبادي هي عبارة عن منصة اتصال بين المدربين والمتدربين لتقديم خطة تدريب وتغذية مخصصة.",
    AllRightsReserved: "جميع الحقوق محفوظة 2019. فاروسا تكنولوجيز",
    CompanyName: "Farosa Technologies Inc",
    Level: "الخطوة",
    HowToApplyProgram: "كيف يمكنني التقدم للحصول على حزمة؟",
    ApplyProgramLevel1: "قم بتنزيل تطبيق رمبادي",
    ApplyProgramLevel2: "ابحث عن هويتي ({0}) في التطبيق",
    ApplyProgramLevel3: "قدم طلبًا بناءً على احتياجاتك وأهدافك",
    ApplyProgramLevel4: "تصميم و تقديم الطلب في غضون الوقت المحدد",
    Close: "أغلق",
    ReadMore: "المزيد",
    Less: "أقل",
    experimental: "تجريبي",
    expert: "خبير",
    amateur: "هواة",
    totalSentPlans: "عدد التطبيقات المباعة",
    download: "حمّل تطبيق رمبادی",
    requestPackage: "طلب خطة",
    info1: "٢. قم بالتسجيل في التطبيق",
    info2: "٣. عد إلى هذه الصفحة",
    info3: "۴. انقر على الزر أدناه",
  },
};
